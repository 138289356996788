body, h1, h2, h3, h4, h5, h6 { margin: 0 }
body { height: 100%; font-size: 17px; }
p { text-align: justify }

%flex_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center { @extend %flex_center }
.pulsate { animation: pulsate 1.3s infinite;}
@keyframes pulsate {
  0% { 
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(153, 24, 63, 0.7);
  }
  50% { 
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 165, 0, 0);
  }
  100% { transform: scale(1) }
}
.whatsapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .2s ease;
  img { width: 30px }

  &:hover { transform: scale(1.1) }
}

.my_btn {
  background: orange;
  border: 0;
  border-radius: 7px;
  margin-top: 10px;
  height: 60px;
  width: 140px;
  font-weight: 500;
  font-size: 20px;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background-color: #99183f;
    color: white;
  }
  
  a {color: unset}
}







// MODAL ========================================================
.modal_window {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  
  display: flex;
  align-items:center;
  justify-content: center;
  
  z-index: 10000 !important;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.modal_window.active {
  opacity: 1;
  pointer-events: all;
}
.modal_toggler {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 15px;
  right: 15px;
  height: 70px;
  width: 70px;
  z-index: 600;
  border-radius: 50%;

  background: orange;
  color: black;
  border: 1px solid black;
  transition: all .2s ease;
  cursor: pointer;

  &:hover {
    background-color: #99183f;
    color: white;
    transform: scale(1.2);
  }
}
.modal_content { position: relative }

.modal_exit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 35px;
  user-select: none;
}

// PRELOADER ========================================================
@keyframes lds-ring {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}




// scroll ========================================================
::-webkit-scrollbar{ width: 14px }
::-webkit-scrollbar-track{ background: #b1b1b1 }
::-webkit-scrollbar-thumb{ 
  background: #99183f;
  border-radius: 7px;
  &:hover { background-color:#781533 }
}


h3 { 
  margin: 20px 0; 
  padding: 0 0 7px 7px;
  border-bottom: 3px dashed orange;
  border-left: 3px solid orange;

}
.content { min-height: calc(100% - 65px - 100px) }
.container {
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.b_y_block {
  color: orange;
  background: #333;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  width: fit-content;
  margin-left: auto;

  &_call {
    border-radius: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: unset;
    font-size: 20px;
    div {margin-right: 5%; font-size: 22px;}
    button {margin-top: 0}
  }
}



// HEADER =============================================================
header {
  background-color: orange;
  height: 65px;
  box-shadow: 0px 9px 5px 0px rgba(34, 60, 80, 0.2);
  

  .header {
    @extend %flex_center;

    div {
      transition: all .2s ease;
      &:hover { transform: scale(1.2) }
      
      a {
        font-size: 24px;
        font-weight: 700;
        color: black;
      }
    }

    &_logo { height: 50px }
  }
}




// TOP =============================================================

.top {
  background: rgb(56, 56, 56) url('./img/house.jpg') center center/cover no-repeat;
  height: calc(100vh - 65px);
  color: white;
  box-shadow: 0px 10px 8px 0px rgba(34, 60, 80, 0.3);

  & .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_text{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 60% at 50% 55%, #000000, rgba(0, 0, 0, 0) 90%);
    letter-spacing: 3px;
    h2 {
      padding: 0 15px 10px;
      margin-bottom: 10px;
      border-bottom: 3px solid orange;
      font-weight: 500;
    }
    h4 {letter-spacing: 0px;}
  }


  &_call {
    margin-top: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &_offer {
      background: radial-gradient(70% 50% at 50% 60%, #000000, rgba(0, 0, 0, 0) 90%);
      h4 { color: orange; margin-bottom: 1% }
    }
    
  }
}

// МЕТАЛЛОЧЕРЕПИЦА ===========================================================
.cards {

  &_italy {
    margin-bottom: 15px;

    &_inner {
      display: flex;
      justify-content: center;
      div {
        width: 33.3333%; 
        height: 280px;
        transition: all .2s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    h5 {
      text-align: center;
      background: #333;
      color: white;
      padding: 20px 0;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      span {
        border-bottom: 2px dashed;
        font-weight: 700;
        background: brown;
        padding: 5px;
      }
    }
  }

  
}



// QUIZ ===========================================================
.quiz_container form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;

  h4 {margin-bottom: 10px;}
  p label span { font-size: 20px !important; }
}



// MIDDLE ===========================================================
// ::selection {
//   background: brown;
//   }
.middle {
  
  &_roofing_materials p { text-indent: 20px }
  
  &_services {
    
    &_cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &_item {
        width: 25%;
        border: 1px solid white;

        &_imgdiv { 
          height: 230px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          overflow: hidden;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            opacity: 0;
            transition: all .2s ease;
            // overflow: auto;
            h5 {
              // height: 100%;
              line-height: 25px;
              padding: 5%;
              // text-indent: 30px;
              display: flex;
              align-items: center;
              // font-weight: 300;
            }

            &:hover {
              background-color: rgba(255, 165, 0, 0.84);
              opacity: 1;
            }
          }

        }
        
        &_text {
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          min-height: 50px;
          margin: 0;
          padding: 10px 5px;
          color: white;
          font-size: 20px;
          background: rgb(72, 16, 33);
        }
      }
    }
    
    .middle_services_title {
      background: #333;
      color: orange;
      text-align: center;
      padding: 20px 0;
      h5 {
        font-size: 30px;
        font-weight: 600;
      }
      span {
        color: white;
        display:block;
        margin-top: 10px;
        font-size: 22px;
      }
    }
  }
}


// Sales & Timer ==================================================
.sales {
  h3 {margin-top: 30px;}
  &_offer {
    padding: 20px 0 20px 0;
    @extend %flex_center;
    justify-content: space-evenly;
    img { width: 250px }
    ul { 
      font-weight: 600;
      font-size: 21px;
      margin-left: 3%; 
      max-width: 480px; 
      text-align: center;
      border: 3px solid rgb(169, 169, 169);
    }
  }

  h4 {
    text-align: center;
    margin-bottom: 25px;
  }

  &_timer {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
  
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      span {
        background: #333;
        color: orange;
        font-size: 40px;
        padding: 10px;
        border-radius: 15px;
        box-shadow: -7px 7px 6px 0px rgba(34, 60, 80, 0.3);
      }
    }
  }
}



// FORMA ===========================================================
.forma {
  &_root {
    background: #333;
    padding: 15px 0;
    color: orange;
  }
  h3 {color: white}
  i {margin-right: 10px}
    
  .form_btn_2 {display: none;}
  &_fields {
    @extend %flex_center;
    input:nth-child(-n+3) { 
      font-size: 20px; 
      color: white;
      &:focus {
        border-bottom: 1px solid orange !important;
        box-shadow: 0 1px 0 0 orange !important;
      }
    }

    .phone_span_plus_7 {
      color: white;
      font-size: 20px; 
      user-select: none; 
      position: absolute;
      left: 32px;
      top: 6.5px;
    }
  }

  textarea {
    width: 100%;
    height: 70px;
    resize: none;
    color: white;
    font-size: 20px;
    &:focus {
      border-bottom: 1px solid orange !important;
      box-shadow: 0 1px 0 0 orange !important;
    }
  }
}



// SLIDER ===========================================================
.portfolio_doposle {
  @extend %flex_center;
  div:first-child { border-right: 1px solid white }
  img { width: 100%; display: block; }
  h5 {
    text-align: center;
    background-color: red;
    color: white;
    padding: 10px;
  }
}

.myslider {
  background: orange;
  overflow: hidden;
  cursor: grab;
  padding: 40px 0;
  &_inner {
    display: flex;
    &_item{
      height: 500px;
      margin: 0 20px;
      transition: all .2s ease;
      &:hover {
        transform: scale(1.07);
      }
      
      div {
        box-shadow: -5px 5px 8px 0px rgba(34, 60, 80, 0.2);
        border-radius: 15px;
        height: 100%;
        width: 500px;
        pointer-events: none;
        position: relative;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 2px;
          left: 2px;
          width: 40px;
          height: 40px;
          background: #333;
          color: white;
          font-size: 24px;
          font-weight: 500;
          padding: 10px;
          border-radius: 50%;
        }
      }
    }
  }
  &_indication {
    text-align: center;
    font-size: 80px;
    line-height: 0;
    padding-top: 40px;
  }
}




// MAPS ===========================================================

.maps {
  margin-top: 20px;
  &_imgs {
    margin: 20px 0;
    @extend %flex_center;
    justify-content: space-evenly;
    img {
      width: 200px;
      border-radius: 10px;
      transition: all .2s ease;

      &:hover {
        background-color:rgb(206, 206, 206);
        transform: scale(1.2);
      }
    }
  }

  &_leaflet { height: 500px }
}

div.leaflet-bottom.leaflet-right { display: none }




// FOOTER ===========================================================
footer {
  height: 100%;
  background: #333;
  color: white;
  padding: 25px 0;

  .footer { 
    @extend %flex_center;
    flex-wrap: wrap;
    justify-content: space-around;
    &_loca {
      display: flex;
      flex-direction: column;
      p {
        text-align: center;
        border-top: 2px solid orange;
        margin: 5px 0 0 0;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

}
// MEDIA =========================================================================
@media (max-width: 1060px) {
  .middle_services_cards_item{ width: 33.3% }
  .myslider_inner_item {height: 420px}

}

@media (max-width: 736px) {
 .top_text h2 {font-size: 40px}
 .portfolio_doposle {flex-wrap: wrap}
 .forma_fields {display: block;}
 .top_call {margin-top: 10%}
 .cards_italy_inner div { height: 235px }

 .form_btn {display: none;}
 .form_btn_2 {display: block !important;}

}

@media (max-width: 712px) {
  header {
    position: sticky;
    top: 0;
    z-index: 401;
  }
  .cards { justify-content: center }
  .middle_services_cards_item{ width: 50% }

  .header_logo { display: none }
  .maps_leaflet { height: 420px }
  .myslider_inner_item div { width: 400px }
}

@media (max-width: 666px) {
  .maps_imgs{ 
    flex-wrap: wrap;
    justify-content: center;
  }
  .myslider_inner_item {height: 380px}

  .sales_offer { 
    flex-wrap: wrap;
    flex-direction: column;
    & div {margin-left: unset}
    ul { margin-top: 25px;}
  }
}

@media (max-width: 560px) {
  .top_text h2 {font-size: 34px}
  .top_text h4 {font-size: 28px}

  h3 {font-size: 34px}
  .top {height: calc(100vh - 120px)}

  .middle_services_cards_item{ width: 100% }
  .maps_leaflet { height: 360px }
  .myslider_inner_item div { width: 350px }

  .footer {padding-bottom: 70px !important}
}

@media (max-width: 440px) {
  .container { padding: 0 }
  .sales_offer {margin-top: 30px }
  .myslider_inner_item { height: 320px }

  .middle_services h5 { font-size: 22px }
  .b_y_block_call {
    flex-direction: column;
    div { margin: 0 0 10px }
    button {width: 80%;}
  }
  .middle_services_cards_item_imgdiv { height: 210px }
}

